<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학자재 별 MSDS 목록"
      tableId="riskHazrd01"
      :editable="editable"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'warning'">
          <q-btn 
            dense
            outline 
            icon="download"
            color="blue-9"
            size="12px"
            style="font-weight:700"
            label="6 x 4"
            @click="linkClick(props.row, col, 's')" />
          <q-btn 
            dense
            outline 
            icon="download"
            color="blue-9"
            size="12px"
            style="font-weight:700"
            label="11 x 9"
            @click="linkClick(props.row, col, 'm')" />
          <q-btn 
            dense
            outline 
            icon="download"
            color="blue-9"
            size="12px"
            style="font-weight:700"
            label="A4"
            @click="linkClick(props.row, col, 'b')" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'mdmChemMaterialId' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
          { index: 3, colName: 'mdmChemMaterialId' },
          { index: 4, colName: 'mdmChemMaterialId' },
          // { index: 5, colName: 'mdmChemMaterialId' },
          { index: 10, colName: 'mdmChemMaterialId' },
          { index: 11, colName: 'mdmChemMaterialId' },
          // { index: 9, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            fix: true,
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            fix: true,
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'mfgVendorName',
            field: 'mfgVendorName',
            label: '제조사',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'warning',
            field: 'warning',
            label: '경고표지',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'custom'
          },
          // {
          //   name: 'msds',
          //   field: 'msds',
          //   label: 'MSDS<br/>요약본',
          //   align: 'center',
          //   style: 'width:90px',
          //   sortable: true,
          //   type: 'custom'
          // },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '구성물질',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          // {
          //   name: 'limitDisplay',
          //   field: 'limitDisplay',
          //   label: '함유량(표기)',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            name: 'limitHigh',
            field: 'limitHigh',
            label: '함유량(최대)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'limitLow',
            field: 'limitLow',
            label: '함유량(최소)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함유량(대표값)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '내부 MSDS',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_MSDS',
            keyText: 'mdmChemMaterialMsdsId',
            sortable: false,
          },
          {
            name: 'col8',
            field: 'col8',
            label: '업체 MSDS',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_MSDS_VENDOR',
            keyText: 'mdmChemMaterialMsdsId',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.msds.url;
      this.printUrl = selectConfig.mdm.mam.chem.print.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col, size) {
      if (col.name === 'materialName') {
        // 화학자재 상세 페이지 열기
        this.$refs['table'].uploadResearchSetting(row, ['col7', 'col8']);
        this.popupOptions.title = '화학자재 상세';
        this.popupOptions.param = {
          mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
          plantCd: row ? row.plantCd : '',
          tabName: 'chemEtc',
        };
        this.popupOptions.target = () => import(`${'./chemDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'warning') {
        // 경고표지 출력
        let thisVue = this;
        this.$http.url = this.$format(this.printUrl, row.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.param = {
          size: size
        };
        this.$http.request(
          _result => {
            let sizeTitle = size === 's' ? '6 x 4' : size === 'm' ? '11 x 9' : 'A4';
            let fileOrgNm = row.materialName + '_' + sizeTitle + '.xlsx';
            let blob = thisVue.$comm.base64ToBlob(_result.data);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
            } else {
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = fileOrgNm;
              link.click();
            }
          }, () => { }
        );
      } 
      // else if (col.name === 'msds') {
      //   // MSDS 요약본 
      //   this.popupOptions.title = 'MSDS 요약본 상세';
      //   this.popupOptions.param = {
      //     mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
      //   };
      //   this.popupOptions.target = () => import(`${'@/pages/chm/msds/chemProcessKnowhowDetail.vue'}`);
      //   this.popupOptions.visible = true;
      //   this.popupOptions.isFull = true;
      //   this.popupOptions.closeCallback = this.closePopup;
      // }
    },
    closePopup() {
      this.$refs['table'].uploadResearch();
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
